import React, { useState } from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import "../styles/header.scss"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faLightbulb, faQuestionCircle, faBook, faMapSigns} from "@fortawesome/free-solid-svg-icons"

const Header = ({ siteTitle }) => {
  const toggleMenu = () => {
    setMenu(!menuActive)
  }
  const [menuActive, setMenu] = useState(false)
  return (
    <header>
      <div className="header-top-row">
        <div className="header-wrapper header-top-row__wrapper">
            <Link className="site-header" to="/">{siteTitle}</Link>
          <nav className="top-nav">
            <Link
              activeClassName="active"
              to="/introduccion"
              className="nav-bold"
            >
              Introducción
            </Link>
            <Link activeClassName="active" to="/preguntas-frecuentes" className="nav-bold">
              Preguntas frecuentes
            </Link>
            <Link
              activeClassName="active"
              to="/recursos"
              className="nav-light"
            >
              Recursos
            </Link>
            <Link
              activeClassName="active"
              to="/involucrate"
              className="nav-light"
            >
              Involúcrate
            </Link>
          </nav>
          <button
            className={
              "mobile-menu-button " +
              (menuActive && "mobile-menu-button__active")
            }
            onClick={toggleMenu}
          >
            Menú
          </button>
        </div>
      </div>
      {/* <div className="header-bottom-row">
        <div className="header-wrapper header-bottom-row__wrapper">
          <nav className="nav-bottom">
         
          </nav>
        </div>
      </div> */}
      <div className={"mobile-nav " + (menuActive && "mobile-nav__active")}>
        <nav className="mobile-nav-inner">
          <Link
            activeClassName="active"
            to="/introduccion"
            className="mobile-menu-item__important"
          >
            Introducción<FontAwesomeIcon className='mobile-menu-icon' icon={faLightbulb} />
          </Link>
          <Link
            activeClassName="active"
            to="/preguntas-frecuentes"
            className="mobile-menu-item__important"
          >
            Preguntas frecuentes<FontAwesomeIcon className='mobile-menu-icon' icon={faQuestionCircle} />
          </Link>
          <Link activeClassName="active" to="/recursos">
            Recursos<FontAwesomeIcon className='mobile-menu-icon' icon={faBook} />
          </Link>
          <Link activeClassName="active" to="/involucrate">
            Involúcrate<FontAwesomeIcon className='mobile-menu-icon' icon={faMapSigns} />
          </Link>
        </nav>
      </div>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
