import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/fede/source/largoplacismo/src/components/layout.js";
import { Link } from "gatsby";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Imagina que entierras unos cristales rotos en un bosque. En uno de los futuros posibles, dentro de 5 años una niña pisa los cristales y se hace una herida. En un futuro posible distinto, dentro de 500 años una niña pisa los cristales y se hace una herida exactamente igual. De acuerdo con el `}<a parentName="p" {...{
        "href": "https://www.nytimes.com/2022/08/05/opinion/the-case-for-longtermism.html"
      }}>{`largoplacismo`}</a>{`, ambas posibilidades son igual de negativas. ¿Por qué deberíamos dejar de preocuparnos por los efectos de nuestras acciones simplemente porque se produzcan en un momento lejano?`}</p>
    <p>{`Es natural que nos preocupemos por las generaciones futuras: seguramente queremos que las vidas de nuestros hijos y nietos sean buenas, incluso antes de que hayan nacido. Como se muestra en el ejemplo anterior, esta preocupación podría ampliarse a un futuro lejano, más allá de las vidas de nuestros nietos.`}</p>
    <p>{`¿Qué sucede cuando dejamos de limitar el alcance de nuestra preocupación a simplemente las siguientes dos o tres generaciones? Si nos planteamos cuántas generaciones seguirán a la nuestra, las implicaciones son impresionantes: nuestras acciones podrían influir en las vidas de muchas más personas de las que cabría esperar.`}</p>
    <p>{`En pocas palabras, la humanidad podría existir durante un periodo increíblemente largo. La esperanza de vida de otras especies sugiere que podríamos tener por delante cientos de miles de años, y la Tierra seguirá siendo habitable durante cientos de millones de años. Si la historia de la humanidad fuera una novela, podríamos estar viviendo en la primera página.`}</p>
    <p>{`Esto resulta muy interesante, y no solo a nivel académico. Es posible que estemos viviendo en un periodo en el que las decisiones que tomemos hoy tengan una gran importancia para las generaciones futuras. Si lo comparamos con una escala temporal más amplia, estamos atravesando una fase de cambio, crecimiento y progreso tecnológico inusualmente rápidos. Por un lado, podríamos ver este periodo como una «era de las oportunidades» para, por ejemplo, asegurarnos de que los valores que integremos en la tecnología que desarrollemos durante este siglo beneficien a todo el mundo durante mucho tiempo. Por otro lado, también podríamos considerarlo una «era de los peligros», es decir, un periodo en el que podría producirse una catástrofe lo suficientemente grave como para limitar de forma permanente el potencial de la humanidad. Dicho esto, parece que hay distintas acciones que podemos llevar a cabo en el presente para reducir las probabilidades de que eso suceda.`}</p>
    <p>{`Seguramente estés de acuerdo en que, en un sentido abstracto, las personas que vivan en el futuro importan. Pero quizá sea la observación anterior (que nuestras acciones de hoy podrían influir en la vida de dichas personas) la que te anime a creer que tener un impacto positivo en el futuro a largo plazo debería ser una prioridad moral clave de nuestro tiempo. Esta es la idea que resume el largoplacismo.`}</p>
    <p>{`En esta introducción se explican las motivaciones del largoplacismo en más profundidad, esto es: que las personas futuras importan desde un punto de vista moral, que es probable que haya un gran número de ellas y que podemos llevar a cabo distintas acciones hoy para contribuir a que tengan una buena vida.`}</p>
    <div className='callout'>
      <p><strong parentName="p">{`¿Prefieres escuchar un pódcast?`}</strong>{` Te sugerimos `}<a parentName="p" {...{
          "href": "https://www.nytimes.com/2022/08/09/opinion/ezra-klein-podcast-will-macaskill.html"
        }}>{`la entrevista de Ezra Klein's con William MacAskill en `}<em parentName="a">{`The New York Times`}</em></a>{`.`}</p>
      <br />
      <p><strong parentName="p">{`¿Prefieres mirar un vídeo?`}</strong>{` Te recomendamos `}<a parentName="p" {...{
          "href": "https://forum.effectivealtruism.org/posts/ge2qXHx3duFsGwXoC/orienting-towards-the-long-term-future-joseph-carlsmith"
        }}>{`"Orienting towards the long-term future"
(Orientarnos hacia el futuro a largo plazo) de Joe Carlsmith`}</a>{`.`}</p>
      <br />
      <p>{`En la `}<Link to="/recursos" mdxType="Link">{`página "Recursos" de este sitio web`}</Link>{` encontrarás muchas más `}<strong parentName="p">{`charlas`}</strong>{` y `}<strong parentName="p">{`pódcast`}</strong>{` relacionados con el largoplacismo.`}</p>
    </div>
    <br />
    <h2 {...{
      "id": "las-personas-que-vivan-en-el-futuro-importan",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#las-personas-que-vivan-en-el-futuro-importan",
        "aria-label": "las personas que vivan en el futuro importan permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Las personas que vivan en el futuro importan`}</h2>
    <p>{`Miles (quizá millones) de generaciones pueden suceder a la nuestra. ¿Deberíamos preocuparnos por ellas?`}</p>
    <p>{`Imagina que un viejo amigo está pasando por una mala racha y quiere hablar contigo por teléfono. Creías que vivíais en el mismo país, pero resulta que acaba de mudarse a otro continente. ¿Tu intención de ayudarlo disminuiría al saber que se encuentra lejos de ti? Por supuesto que no.`}</p>
    <p>{`Muchas personas estarán de acuerdo en que la distancia que nos separa de una persona no determina cuánto importa su vida. Siempre que sea igual de fácil ayudarla, sus necesidades no importan menos por el simple hecho de que viva lejos de nosotros.`}</p>
    <p>{`Si las personas importan lo mismo independientemente de dónde hayan nacido, ¿no deberían importar por igual con independencia de cuándo hayan nacido?`}</p>
    <p>{`No cabe duda de que haber nacido antes o después no es motivo para que una persona importe más o menos. Desde la perspectiva de nuestros ancestros, nosotros en algún momento fuimos personas futuras, y entre ellos y nosotros median muchas generaciones. Sería extraño afirmar que nuestras vidas importan menos por el mero hecho de haber nacido después que ellos: nuestra felicidad y nuestro dolor son tan reales e importantes como los de las generaciones que nos precedieron. Las personas que vivan en un futuro lejano opinarán lo mismo cuando comparen el valor de sus vidas con el de las nuestras.`}</p>
    <p>{`Bien es cierto que podría haber razones para valorar más las vidas de las personas cercanas en el tiempo y el espacio (por ejemplo, porque se trata de familiares o amistades cercanas, o porque su proximidad implica que te resulta más sencillo mejorar sus vidas). No obstante, nunca es el caso de que el simple hecho de su cercanía en el tiempo o el espacio importe moralmente. El ejemplo de enterrar unos cristales rotos en un bosque, que planteó el difunto filósofo Derek Parfit, ayuda a ilustrar este principio.`}</p>
    <h2 {...{
      "id": "el-potencial-de-la-humanidad-es-enorme",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#el-potencial-de-la-humanidad-es-enorme",
        "aria-label": "el potencial de la humanidad es enorme permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`El potencial de la humanidad es enorme`}</h2>
    <p>{`Un motivo por el que salvar una vida humana es tan valioso es que, al hacerlo, también salvamos el potencial de esa persona. Sin embargo, no hace falta que alguien muera. También podemos lamentar que algunas personas no alcancen todo su potencial. Por ejemplo, una científica joven y prometedora podría no llegar a hacer los descubrimientos que podría haber logrado por distintos motivos, como la discriminación o la falta de apoyo. Cuanto mayor es el potencial de una persona, más importante parece protegerlo y hacer lo posible por asegurarnos de que se materialice, y mayor es la pérdida si esto no se consigue. Sin embargo, esto no se aplica únicamente a los individuos: la humanidad en su conjunto también tiene su propio potencial, que es la posibilidad latente de un futuro extraordinario. Como en el caso de la científica prometedora, podemos alcanzar de forma colectiva prácticamente todo nuestro potencial o echarlo a perder casi por completo. Por este motivo, la magnitud de nuestro potencial importa. Cuanto mejor y más expansivo parezca ser nuestro futuro, más importante será protegerlo.`}</p>
    <p>{`¿Cuántas personas podrían vivir en el futuro? ¿Cómo de buenas (o malas) podrían ser sus vidas? Como es obvio, no podemos dar una respuesta precisa, pero sí podemos buscar pistas sobre nuestro potencial, es decir, cómo podría ser nuestro futuro si las cosas nos van relativamente bien. Si reflexionamos sobre esta cuestión, nos damos cuenta de que puede que aún no haya nacido la inmensa mayoría de los humanos. Prácticamente todo lo que consideramos más importante podría ser disfrutado durante generaciones y generaciones, mucho más allá de nuestros nietos.`}</p>
    <p>{`Para empezar, la especie de mamífero típica sobrevive, de media, un millón de años aproximadamente. La especie humana, el `}<em parentName="p">{`Homo sapiens`}</em>{`, surgió hace unos 300 000 años. Por tanto, si los humanos sobrevivimos durante un periodo similar al de la mayoría de los mamíferos, aún nos quedarían 200 000 años por delante, o lo que es lo mismo, casi 1000 generaciones futuras. Bien es cierto que los humanos no somos una especie de mamífero al uso, ya que contamos con los medios tecnológicos necesarios para evitar las amenazas a nuestra extinción y recuperarnos de ellas. Podríamos perdurar aún más tiempo que el resto de los mamíferos.`}</p>
    <p>{`Por tanto, podemos centrar nuestra atención en el `}<a parentName="p" {...{
        "href": "https://es.wikipedia.org/wiki/Futuro_de_la_Tierra"
      }}>{`futuro de la Tierra`}</a>{`. Es probable que nuestro planeta siga siendo habitable durante varios cientos de `}<a parentName="p" {...{
        "href": "https://www.researchgate.net/publication/253770007_Long-term_Astrophysical_Processes"
      }}>{`millones de años`}</a>{` antes de que se vuelva estéril por la influencia del sol. Si la humanidad sobreviviera durante apenas un 1 % de ese periodo, habría alrededor de medio millón de generaciones más después de la nuestra. Si asumimos que, en el futuro, el número de personas vivas por siglo será similar al del pasado reciente, el resultado son al menos un trillón de vidas humanas futuras, es decir, 10 000 veces más que el número de humanos que han vivido hasta la fecha.`}</p>
    <p>{`¿Se detendrá ahí la humanidad? Apenas transcurrieron 66 años entre el primer `}<a parentName="p" {...{
        "href": "https://es.wikipedia.org/wiki/Wright_Flyer"
      }}>{`vuelo sostenido en una aeronave más pesada que el aire`}</a>{` y los primeros pasos de un humano `}<a parentName="p" {...{
        "href": "https://es.wikipedia.org/wiki/Apolo_11"
      }}>{`en la luna`}</a>{`. Imaginemos el progreso tecnológico que podemos lograr en el campo de la navegación espacial en cien o, incluso, mil años. Si tenemos en cuenta lo que ya sabemos, parece perfectamente posible que los humanos podamos acabar aventurándonos más allá de la Tierra. Si decidimos viajar más allá de nuestro planeta, quizá llegue el día en que el cielo estrellado esté repleto de miles estrellas a las que también consideremos nuestro hogar.`}</p>
    <p>{`Esto puede sonar mucho a ciencia ficción o puede parecer que no está respaldado por pruebas contundentes, pero nadie sabe con exactitud cómo será el futuro. Lo que importa es que el futuro de la humanidad podría ser extraordinariamente vasto tanto en duración como en alcance.`}</p>
    <p>{`La escala del futuro puede ser difícil de comprender. Cuando las cifras son muy elevadas, empiezan a sonarnos todas igual. Para combatir esto, el físico `}<a parentName="p" {...{
        "href": "https://es.wikipedia.org/wiki/James_Jeans"
      }}>{`James Jeans`}</a>{` propuso la siguiente metáfora. Imagina un sello de correos encima de una moneda. Si el grosor combinado de esa moneda y ese sello representa la duración de nuestra especie, entonces el grosor del sello simboliza la extensión de la civilización humana hasta donde se tienen registros. Ahora imagina que colocas la moneda en lo alto de un obelisco de 20 metros. Si el sello representa toda la historia de la civilización humana, el obelisco simboliza la antigüedad de la Tierra. Ahora pasemos al futuro. Un árbol de 5 metros situado encima del obelisco representa el futuro habitable de la Tierra. Detrás de este conjunto de elementos está el monte Cervino. Su altura simboliza el `}<a parentName="p" {...{
        "href": "https://es.wikipedia.org/wiki/Anexo:Cronolog%C3%ADa_hipot%C3%A9tica_del_futuro_lejano"
      }}>{`futuro habitable del universo`}</a>{`.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/e063102bfc402817a6903c620e338450/50ed0/obelisk.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "57.00000000000001%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAAsTAAALEwEAmpwYAAACEElEQVQoz3VQ2W4SYRidV/DKqyZtiozgwLAOMBsz8/8/s7G1DMOspVA2saWtaDUmphfGJ/AFfAhN1JsuikyvjFHfx4BLaaLJyZd8yTnf+c7BJM0Bmg01G2iO9Ad8yZRW1v8BkzSH0xxa91nNExeHHFTxdnrHqOyLqn1N1d1/iHnNHRveq+HoUXsg646oOWq9/ebtu7rZKyrWLxLQXR4ZkmIB3RXVFXGzN32/5/7o298O+9PhpFCyKkb38vJjwx4Kir1k26jiJ3KwIFRR2bvhfH96+mnH+HIw+P54/+XzF5TYaNjDs7Nz0xvxcguVPVj2CmI9QnIZWs0wqiCbQPsdAZOr/mtz++vpkw/7/Zbdp2Gz5Y/PLy6c9gMOmUB3UcWPZwFJQUm1KFZPFWQOGXD5AiYoltzoWk5/q+6Jqs0h0+1MZrOZvzfhkIkWtrVokldqPo+MDKMm86VIgs+yOtBdbJFKsdiSySs21F0WNXcHD4Ng3hkcMaCRyCE8zm7g6Wqz63QOQ9E8HmPuEIW1TTJDq9jfPsFyMrDZG59cBUF3eJzMK3iMDkVz6+E0K9U7oxNG2trAM3icWdtMrIfT2Gp7UHcZYIwOnl4F893ehMjCWFq8S7KRBBchWR41tu1Rji+TFCQpSKSEm+KySwNjfPQsmH82ne6t25thIkekiqEohcdoIiUIskGLNZKCiRy6lyz+BJlR0ZZESvosAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "The scale of the future",
            "title": "The scale of the future",
            "src": "/static/e063102bfc402817a6903c620e338450/5a190/obelisk.png",
            "srcSet": ["/static/e063102bfc402817a6903c620e338450/772e8/obelisk.png 200w", "/static/e063102bfc402817a6903c620e338450/e17e5/obelisk.png 400w", "/static/e063102bfc402817a6903c620e338450/5a190/obelisk.png 800w", "/static/e063102bfc402817a6903c620e338450/c1b63/obelisk.png 1200w", "/static/e063102bfc402817a6903c620e338450/29007/obelisk.png 1600w", "/static/e063102bfc402817a6903c620e338450/50ed0/obelisk.png 3514w"],
            "sizes": "(max-width: 800px) 100vw, 800px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`La supervivencia de la humanidad a lo largo de estas escalas temporales tan inmensas solo es deseable si las vidas de las personas futuras merecen ser vividas. Por suerte, hay motivos para sospechar que el futuro podría ser extraordinariamente bueno. Ya hemos `}<a parentName="p" {...{
        "href": "https://ourworldindata.org/a-history-of-global-living-conditions-in-5-charts"
      }}>{`conseguido avances impresionantes`}</a>{`: el porcentaje de personas que viven en `}<a parentName="p" {...{
        "href": "https://ourworldindata.org/grapher/world-population-in-extreme-poverty-absolute?stackMode=relative"
      }}>{`pobreza extrema`}</a>{` ha descendido de alrededor del 90 % en 1820 a menos del 10 % en el 2015 (y las cifras absolutas también han disminuido). Durante el mismo periodo, la `}<a parentName="p" {...{
        "href": "https://ourworldindata.org/grapher/global-child-mortality-timeseries?country=~OWID_WRL"
      }}>{`mortalidad infantil`}</a>{` ha caído de más del 40 % a menos del 5 %, y el número de personas que viven en `}<a parentName="p" {...{
        "href": "https://ourworldindata.org/grapher/world-pop-by-political-regime?stackMode=relative&country=~OWID_WRL"
      }}>{`democracia`}</a>{` ha aumentado de menos del 1 % a la mayor parte de la humanidad. Por si fuera poco, podemos seguir progresando y tenemos razones para esperar que los futuros avances médicos y científicos sigan mejorando la calidad de vida en el futuro.`}</p>
    <p>{`Es cierto que al mundo aún le queda mucho por hacer para solucionar los problemas más acuciantes, como la pobreza extrema, la injusticia, el sufrimiento animal y la destrucción provocada por el cambio climático. Señalar el alcance de los futuros positivos que se abren ante nosotros no debería hacernos olvidar los problemas del presente. De hecho, darnos cuenta de lo bien que podría irnos puede servirnos de motivación para trabajar en los problemas más urgentes de nuestro tiempo, que no tienen por qué ser perennes: si las cosas van bien, resolverlos ahora podría implicar resolverlos para siempre.`}</p>
    <p>{`El futuro a largo plazo podría ser extraordinario, pero no está garantizado. También puede darse el caso de que el futuro sea aciago; por ejemplo, a causa del estancamiento, de un tipo de régimen político totalitario particularmente estable o de conflictos constantes. Sin embargo, esta no es razón para abandonar la idea de proteger el futuro a largo plazo. Más bien al contrario: ser conscientes de que el futuro podría ser nefasto debería poner de relieve aún más la oportunidad de mejorarlo. Evitar futuras tragedias y adversidades es tan importante como aumentar las probabilidades de tener un futuro fabuloso.`}</p>
    <p>{`No sabemos exactamente cómo será el futuro de la humanidad. Lo que importa es que el futuro puede ser extraordinariamente bueno o extremadamente malo, que seguramente su alcance sea inmenso y que albergará tanto a la mayoría de las personas que vivirán a lo largo de la historia como gran parte de lo que hoy nos parece valioso. Aumentar las probabilidades de alcanzar en algún momento ese potencial o mejorar las vidas de miles de generaciones futuras podría tener una importancia tremenda.`}</p>
    <h2 {...{
      "id": "nuestras-acciones-podrían-influir-en-el-futuro-a-largo-plazo",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#nuestras-acciones-podr%C3%ADan-influir-en-el-futuro-a-largo-plazo",
        "aria-label": "nuestras acciones podrían influir en el futuro a largo plazo permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Nuestras acciones podrían influir en el futuro a largo plazo`}</h2>
    <p>{`¿Podemos hacer algo en el presente que mejorara o protegiera de manera fiable el futuro a muy largo plazo? Para las personas que vivieron en gran parte de nuestro pasado, la respuesta seguramente sería «no». Sin embargo, hay motivos convincentes para pensar que este momento de la historia podría tener una influencia inusual en el futuro de la humanidad.`}</p>
    <p>{`Un claro ejemplo es el cambio climático. Sabemos más allá de toda duda razonable que la actividad humana altera el clima de la Tierra y que el cambio climático tendrá efectos devastadores. También sabemos que algunos de esos efectos podrían prolongarse durante mucho tiempo, ya que el dióxido de carbono puede permanecer en la atmósfera terrestre durante decenas de miles de años. Dicho esto, cabe señalar que podemos controlar cuánto daño causamos; por ejemplo, redoblando nuestros esfuerzos para desarrollar tecnologías verdes, creando más métodos para aprovechar fuentes de energía con cero emisiones y poniendo precio a las emisiones de carbono en consonancia con su verdadero coste social. Por estas razones, los largoplacistas tienen motivos de peso para preocuparse por el cambio climático, y muchos de ellos están trabajando en cuestiones relacionadas con este problema. Mitigar los efectos del cambio climático es la «prueba de concepto» ideal para influir de manera positiva en el futuro a largo plazo, pero no es el único ejemplo.`}</p>
    <p>{`Muchas personas que aún siguen vivas eran pequeñas cuando la humanidad descubrió cómo podría autodestruirse. En julio de 1945 se detonó la `}<a parentName="p" {...{
        "href": "https://es.wikipedia.org/wiki/Prueba_Trinity"
      }}>{`primera arma nuclear`}</a>{` en el lugar conocido hoy como Trinity Site, en Nuevo México (EE. UU.). Aparte de la inmediata devastación que causaría, una guerra nuclear a gran escala podría provocar un «`}<a parentName="p" {...{
        "href": "https://es.wikipedia.org/wiki/Invierno_nuclear"
      }}>{`invierno nuclear`}</a>{`» intenso y prolongado que dejaría a su paso una enorme cantidad de víctimas en todo el mundo a causa de las pérdidas generalizadas de cosechas. A pesar de este riesgo, desde que se llevó a cabo la prueba Trinity, las armas nucleares han proliferado hasta llegar a las decenas de miles, muchas de ellas en estado de alerta instantánea hoy en día.`}</p>
    <p>{`Esto plantea la posibilidad de que ocurra una catástrofe existencial, es decir, un suceso que limitara de forma permanente el potencial de la humanidad (por ejemplo, provocando nuestra extinción). Dejando a un lado el hecho obvio de que una catástrofe existencial podría provocar pérdidas indecibles para las personas vivas en ese momento, contribuir a evitar una catástrofe existencial en este siglo también aumenta las probabilidades de que miles de generaciones futuras puedan llegar a vivir, lo cual supone un impacto positivo claro en el futuro a largo plazo.`}</p>
    <p>{`Por desgracia, las armas nucleares no parecen ser la única causa posible de una catástrofe existencial. Hablemos de la biotecnología. Hemos visto que pandemias como la de covid-19 pueden tener efectos devastadores. Sin embargo, debido a la biotecnología moderna, será posible modificar genéticamente patógenos para que sean mucho más letales o transmisibles de lo que serían en su forma natural. Esto supone una amenaza para millones, si no miles de millones, de vidas. Además, las barreras de entrada para crear una pandemia artificial seguramente bajen más aún en las próximas décadas: el `}<a parentName="p" {...{
        "href": "https://es.wikipedia.org/wiki/Proyecto_Genoma_Humano"
      }}>{`primer proyecto para secuenciar el genoma humano completo`}</a>{` tardó en realizarse unos 15 años (se completó en el 2003) y costó unos 500 millones de dólares. Hoy en día se puede secuenciar un genoma completo en menos de una hora y por unos 1000 dólares. Si bien la síntesis de ADN sigue siendo más cara, su precio ya ha bajado en `}<a parentName="p" {...{
        "href": "http://www.synthesis.cc/synthesis/2016/03/on_dna_and_transistors"
      }}>{`un factor de más de 1000`}</a>{`.`}</p>
    <p>{`Pasemos ahora a la inteligencia artificial (IA). Cada vez más expertos destacados en IA, como `}<a parentName="p" {...{
        "href": "https://es.wikipedia.org/wiki/Stuart_J._Russell"
      }}>{`Stuart Russell`}</a>{`, `}<a parentName="p" {...{
        "href": "https://www.ted.com/talks/stuart_russell_3_principles_for_creating_safer_ai?language=es"
      }}>{`intentan anunciarnos`}</a>{` los peligros, así como las ventajas, que podría traer consigo la IA avanzada. En primer lugar, los expertos han observado que es perfectamente plausible que desarrollemos una IA general con capacidades sobrehumanas y que esto suceda durante nuestras vidas o durante las de nuestros hijos. Esto podría transformar radicalmente todos los aspectos de la vida. Pensemos en cómo hemos conseguido los humanos controlar casi por completo al resto de los primates. Prueba de ello son los zoos, donde los confinamos por mero entretenimiento. La historia no ha seguido este curso porque gozáramos de una ventaja en cuestión de fuerza ni porque tuviéramos la intención de subyugar a otros primates, sino gracias a nuestra inteligencia. ¿Podemos tener la seguridad de que todo irá bien si creamos máquinas que sean para nosotros lo que nosotros somos para los demás primates? A los expertos en IA también les preocupa que estos sistemas de IA tan potentes puedan llevar integrados los valores equivocados y, por tanto, supongan una amenaza existencial. Es cierto que diseñar sistemas de IA seguros que estén alineados con los valores que consideramos buenos es un problema difícil, pero si no conseguimos solucionarlo podríamos perder el control del futuro a largo plazo. Por tanto, resolver la alineación de la IA podría ser uno de los desafíos más importantes a los que nos enfrentamos en este siglo.`}</p>
    <p>{`Hemos mencionado los ejemplos de la guerra nuclear, las pandemias creadas artificialmente y la IA no alineada no porque sean las amenazas más importantes para el potencial a largo plazo de la humanidad necesariamente, sino porque ilustran algunos aspectos generales de los riesgos existenciales. Hace un siglo, prácticamente nadie se imaginaba que fueran a existir. A medida que inventamos tecnologías más y más potentes, quizá esa tendencia continúe. Este siglo podría estar caracterizado por una vulnerabilidad inusual de nuestra sociedad global, tan conectada por redes.`}</p>
    <p>{`El filósofo de Oxford Toby Ord resume la importancia moral de los riesgos existenciales en su libro `}<a parentName="p" {...{
        "href": "https://theprecipice.com/"
      }}><em parentName="a">{`The Precipice`}</em></a>{` (El precipicio, aún sin traducir):`}</p>
    <p>{`«Cuando pienso en los millones de generaciones futuras que vendrán, no me cabe duda de la importancia de proteger el futuro de la humanidad. Arriesgarnos a destruir ese futuro por conseguir una ventaja circunscrita únicamente al presente me parece una actitud profundamente estrecha de miras y peligrosamente cortoplacista. Esa irresponsabilidad privilegia una fracción mínima de nuestra historia frente a su inmensa totalidad, privilegia a una diminuta minoría de los humanos frente a la abrumadora mayoría de los que aún no han nacido y privilegia este siglo en particular frente a los millones o quizá miles de millones que quedan por venir».`}</p>
    <p>{`Además de evitar riesgos existenciales, podríamos influir positivamente en el futuro a largo plazo de otras formas. Por ejemplo, podríamos presentar una petición para `}<a parentName="p" {...{
        "href": "https://a764aa28-8f1b-4abd-ad69-eed71af9e23a.filesusr.com/ugd/b589e0_6cc51397ac4b4d78b2f68d8f489b0847.pdf"
      }}>{`reformar nuestras instituciones políticas`}</a>{` con el objetivo de que defiendan los intereses de las generaciones futuras, es decir, las personas que vivirán los efectos de las decisiones políticas del presente, pero que no tienen voz ni voto para influir en ellas. Esta iniciativa podría adoptar la forma de comités y oficinas nacionales, `}<a parentName="p" {...{
        "href": "https://es.wikipedia.org/wiki/Voto_Demeny"
      }}>{`nuevos`}</a>{` métodos de votación o marcos y paneles internacionales. Hay buenas noticias en este frente: el secretario general de las Naciones Unidas `}<a parentName="p" {...{
        "href": "https://www.un.org/es/common-agenda"
      }}>{`ha anunciado recientemente una agenda`}</a>{` para plantear la puesta en marcha de nuevos proyectos de la ONU centrados en la protección de las generaciones futuras.`}</p>
    <p>{`El momento actual puede tener una influencia inusual en los valores que acaben teniendo las generaciones venideras. La historia nos enseña que las fases en las que se producen cambios rápidos suelen caracterizarse por decisiones contingentes e impredecibles en materia de valores morales o políticos, que luego se anquilosan durante largos periodos de tiempo. La etapa que estamos viviendo encarna estos rápidos cambios, no solo por los avances tecnológicos descritos anteriormente, sino también porque el mundo se está volviendo cada vez más `}<a parentName="p" {...{
        "href": "https://ourworldindata.org/grapher/globalization-over-5-centuries"
      }}>{`globalizado`}</a>{` cultural y políticamente, y porque estamos atravesando un periodo de crecimiento económico continuado que `}<a parentName="p" {...{
        "href": "https://www.cold-takes.com/this-cant-go-on/"
      }}>{`parece meteórico`}</a>{` si lo `}<a parentName="p" {...{
        "href": "https://ourworldindata.org/grapher/world-gdp-over-the-last-two-millennia"
      }}>{`ponemos en el contexto`}</a>{` de la historia de la humanidad en su conjunto.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/108d50ce05421e1b29f2c7d33412e3d0/292f3/world-gdp.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "70.5%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAAAsTAAALEwEAmpwYAAABN0lEQVQ4y61TyU7DMBT0/5+4c+WG+AG+o5cKwSlRq1bZ8Jp4GRi7ScNyKcTS6OUtHs9z/ETXdaiqCofDAafTCefzGcfjMaNpGtR1nTEMA2KM8N4vCCFgXnf3j3h4eobQWqNt27yh73vwAIKx9TfzrJVSQimVrXNuIXzZ7fG2f4UwxuSC9Wm3rJRSBpcPEYJElM925uRfgJSgtIGw1uZ2SLo+8WZCANpYiPmiaTch3EIh26WgHwr/o877AKk2usNC6CGV3uYOrwp1UcgHvVZ4tenX93aJfPG5vx8kxFzM9xhCvNiAGNM3P15iYakt3QWEGGGsKz+FhEwopeHGCSMxefT9AGtd9idf/I4xN8K5Mdc3bbf471KBUyc4j5xRQxizQKkys8aUHGvme56mKc8ywVprTOH4JPwAjqRKTwYWW5MAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "World GDP",
            "title": "World GDP",
            "src": "/static/108d50ce05421e1b29f2c7d33412e3d0/5a190/world-gdp.png",
            "srcSet": ["/static/108d50ce05421e1b29f2c7d33412e3d0/772e8/world-gdp.png 200w", "/static/108d50ce05421e1b29f2c7d33412e3d0/e17e5/world-gdp.png 400w", "/static/108d50ce05421e1b29f2c7d33412e3d0/5a190/world-gdp.png 800w", "/static/108d50ce05421e1b29f2c7d33412e3d0/c1b63/world-gdp.png 1200w", "/static/108d50ce05421e1b29f2c7d33412e3d0/29007/world-gdp.png 1600w", "/static/108d50ce05421e1b29f2c7d33412e3d0/292f3/world-gdp.png 3400w"],
            "sizes": "(max-width: 800px) 100vw, 800px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`Si se quedan fijados pronto una serie de valores perjudiciales o antidemocráticos durante mucho tiempo y no conseguimos evitar que esto suceda, probablemente habremos fallado a las generaciones futuras. Esta conjetura apunta a otra manera de influir positivamente en el futuro a largo plazo, esto es: reducir las probabilidades de que un único conjunto de valores morales, culturales o políticos acabe dominando sobre el resto de forma prematura, antes de que se hayan podido contemplar todos los conjuntos y perspectivas disponibles.`}</p>
    <h2 {...{
      "id": "unir-todas-las-piezas",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#unir-todas-las-piezas",
        "aria-label": "unir todas las piezas permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Unir todas las piezas`}</h2>
    <p>{`Muchas personas estarán de acuerdo en que, en términos morales, las generaciones futuras merecen tanta consideración por nuestra parte como nuestra propia generación, y que intrínsecamente no tenemos una mayor importancia que las personas que vivan en el futuro. Por un lado, esta afirmación moral puede aceptarse sin dificultad en parte porque no está claro de qué manera tomárnosla en serio podría alterar nuestras prioridades. Por otro lado, resulta muy natural asumir que no podemos hacer nada para mejorar las vidas de las personas futuras de dentro de miles o millones de años. Quizá las repercusiones de nuestros esfuerzos se diluyan rápidamente con el paso del tiempo o quizá nuestra incertidumbre sobre el futuro sea tal que no podamos saber con seguridad si lo que hagamos ahora tendrá un impacto positivo o negativo. No obstante, como hemos visto, reflexionar sobre el momento de la historia en el que nos encontramos nos lleva a pensar en acciones que podemos realizar en el presente para influir en el futuro a largo plazo o, incluso, para determinar si llegan a existir generaciones futuras o no.`}</p>
    <p>{`Si verdaderamente nos tomamos el tiempo necesario para valorar lo que podemos conseguir en el largo plazo, esta posibilidad adquiere una importancia destacable: nuestras decisiones actuales podrían repercutir de manera considerable en miles de millones de personas que aún no han nacido.`}</p>
    <p>{`Un ejemplo concreto de estas decisiones podría ser desarrollar mejores medidas para combatir futuras pandemias, como un `}<a parentName="p" {...{
        "href": "https://80000hours.org/podcast/episodes/pardis-sabeti-sentinel/"
      }}>{`sistema de detección temprana de nuevos patógenos`}</a>{`. Otro podría ser presionar para que se creen instituciones políticas que protejan a las generaciones futuras o enviar propuestas a la `}<a parentName="p" {...{
        "href": "https://www.finmoorhouse.com/writing/our-common-agenda%23what-can-you-do"
      }}>{`Cumbre sobre el Futuro`}</a>{`, que se celebrará en el 2023. O, por último, emprender investigaciones en materias como la economía, la historia, el derecho o la filosofía para descubrir otros mecanismos que nos permitan influir en el futuro a largo plazo, o llevar a cabo investigaciones técnicas orientadas a garantizar que los sistemas de IA potentes tomen decisiones transparentes y comprensibles que estén alineadas con los valores humanos.`}</p>
    <h2 {...{
      "id": "conclusión",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#conclusi%C3%B3n",
        "aria-label": "conclusión permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Conclusión`}</h2>
    <p>{`Es comprensible que estas ideas nos parezcan demasiado extrañas o sacadas de un relato de ciencia ficción. Sin embargo, no hace falta que todos los argumentos presentados arriba nos parezcan convincentes para que encontremos valioso alguno de los aspectos del largoplacismo. El largoplacismo no es una postura limitada y monolítica acerca de lo que debemos priorizar y los motivos por los que debemos hacerlo. Se trata más bien de una perspectiva amplia que puede y, de hecho, debe entroncarse con otros campos de investigación.`}</p>
    <p>{`Por tanto, no debemos descartar estas ideas por el mero hecho de que nos suenen descabelladas: ideas morales que en su momento parecían vanguardistas, como la de tratar a los animales humanamente, son normales hoy en día. El futuro a largo plazo podría ser muy extraño, podría caracterizarse por un alcance y unos logros extraordinarios, y también podría tener un final prematuro y trágico. Pero lo que sabemos casi con total seguridad es que `}<a parentName="p" {...{
        "href": "https://www.cold-takes.com/all-possible-views-about-humanitys-future-are-wild/"
      }}>{`no se parecerá`}</a>{` al presente.`}</p>
    <p>{`Aún hay mucha incertidumbre acerca de cuáles son las mejores formas de asegurarnos de que el futuro vaya bien. Por ese motivo, consideramos que afirmar que no podemos hacer nada para ayudar a las personas que vivan en el futuro a muy largo plazo parece un exceso de confianza desmesurado.`}</p>
    <p>{`Así pues, el largoplacismo nos invita a no rendirnos cuando nos enfrentemos a esta incertidumbre, sino que sigamos ampliando nuestro conocimiento, ya que las decisiones que tomemos en este siglo podrían determinar el futuro que nos espera. El momento de la historia en el que nos encontramos parece indicar que este proyecto es una de las máximas prioridades de nuestro tiempo. Y nos queda mucho por aprender.`}</p>
    <p>{`Esta es una introducción breve a un tema muy amplio. Hay multitud de caminos que explorar para aprender más. Puedes empezar por la página `}<Link to='/recursos' mdxType="Link">{`Recursos`}</Link>{` de este sitio web. Si te interesa contribuir a alguna de estas iniciativas, echa un vistazo a la página `}<Link to='/involucrate' mdxType="Link">{`Involúcrate`}</Link>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      