import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import "../styles/footer.scss"

const Footer = ({ siteTitle }) => (
  <footer>
   <div className="footer-outer">
        {/*<nav>
          <Link to="/introduccion">Introducción</Link>
          <Link to="/faq">FAQ</Link>
          <Link to="/involucrate">Involúcrate</Link>
          <Link to="/recursos">Recursos para saber más</Link>
        </nav>*/}
      <div className="footer-inner">
        <span>
        &copy;2023 Largoplacismo.com
        </span>
        <Link to='/acerca-de'>Acerca de este sitio</Link>
      </div>
    </div>
  </footer>
)

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: ``,
}

export default Footer
